<template>
    <div class="hero">
      <div class="left">
        <div class="top">
          <h1>An editor that helps you write clean codes.</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu.</p>
          <div class="action">
            <button>Try for free</button>
          </div>
        </div>
        <div class="btm">
          <div class="stars">
            <img src="../assets/icons/star.png" alt="">
            <img src="../assets/icons/star.png" alt="">
            <img src="../assets/icons/star.png" alt="">
            <img src="../assets/icons/star.png" alt="">
            <img src="../assets/icons/star.png" alt="">
          </div>
          <p style="font-weight: 800; margin: 20px 0px;">Best code editor in market!</p>
          <p style="color: var(--grey);">Consectetur adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu, aliquam nulla tincidunt gravida. Cursus convallis dolor semper pretium ornare.</p>
          <span class="profile">
            <img src="../assets/icons/profile.png" alt="">
            <p>Denny Jones</p>
          </span>
        </div>
      </div>
      <div class="right">
      </div>
    </div>
</template>

<script>

export default {
  data(){
    return{
    }
  }
}
</script>

<style>
  .hero{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 100px 0px 0px 0px;
  }
  .left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 505px;
    margin: 50px 0px 0px 70px;
  }
  .top{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .top h1{
    font-size: 58px;
  }
  .top p{
    margin: 20px 0px 0px;
    color: var(--grey);
  }
  .top .action button{
    width: 150px;
    height: 50px;
    border: none;
    color: var(--white);
    background: var(--black);
    border-radius: 3px;
    font-weight: 700;
    margin: 20px 20px 0px 0px;
  }

  /* bottom content */
  .btm{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .btm .stars{
    width: 110px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin: 50px 0px 0px;
  }
  .profile{
    width: 150px; 
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 10px;
    margin: 20px 0px 0px;
  }
  .profile p{
    font-size: 16px;
  }
  .right{
    width: 583px;
    height: 573px;
    background-image: url(../assets/images/rightillustration.jpeg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 50px 70px 0px 0px;
  }




  @media(max-width: 1000px){
    .hero{
      flex-direction: column-reverse;
    }
  }
  @media (max-width: 600px) {
    .left{
      margin: 50px 0px ;
      width: 100%;
      text-align: center;
      width: 90%;
    }
    .top,.btm{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .right{
      width: 450px;
      margin: 0;
    }
    .top .action button{
      margin: 10px 0px 0px;
    }
  }
  @media(max-width: 450px){
    .right{
      width: 320px;
      height: 320px;
      margin: 0;
    }
  }
</style>