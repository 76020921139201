<template>
  <nav>
    <h5><span>/</span>RAREBLOCKS</h5> 
    <ul :class="showMenu && 'false'"> 
      <li>Features</li>
      <li>Pricing</li>
      <li>Support</li>
    </ul>

    <div class="hamburger" @click="toggleMenu">
    </div>
    <div class="action">
      <button>
        Try for free
      </button>
    </div>
  </nav>
  <router-view/>
</template>

<script>
export default {
  data(){
    return{
      showMenu: true,
    }
  },
  methods:{
    toggleMenu(){
      this.showMenu = !this.showMenu
      console.log('oi')
    }
  }
}
</script>

<style>
:root {
  --background-color: white;
  --color: #151515;
  --white: white;
  --black: #18181B; 
  --grey: #5e5e5e; 
}
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Inter:wght@400;500;600;700;800;900&family=Plus+Jakarta+Sans:wght@300;500;600;700&display=swap');

*{
    margin: 0;
    padding: 0;
}
#app{
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* fonts */
h1, h2, h3, h4, h5, h6, button{
    font-weight: 800;
    font-family: 'plus jakarta sans';
}
p, label, span, li, a, ul{
    font-family: 'inter';
    text-decoration: none;
    list-style: none;
}
li, a{
    font-weight: 500;
    text-decoration: none;
    list-style: none;
    color: var(--color);
    cursor: pointer;
}
nav {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  padding: 0px 0px 10px 0px;
  margin-top: 10px;
  background: var(--background-color);
}
nav h5 {
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 16px;
  margin-left: 20px;
}
nav span{
  color: #F43F5E;
}
nav ul{
  width: 305px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav .action button{
  width: 135px;
  height: 45px;
  border: none;
  color: var(--white);
  background: var(--black);
  border-radius: 3px;
  font-weight: 700;
  margin-right: 20px;
}
nav .hamburger{
  display: none;
}





@media (max-width: 1000px){
  nav{
    margin: 0px;
    height: 60px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.032);
  }
  nav .action{
    display: none;
  }
  nav .hamburger {
    display: block;
    width: 35px;
    height: 35px;
    background-image: url("./assets/icons/hamburger.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-right: 20px;
  }
  nav h5 {
    font-size: 20px;
    font-weight: 900;
    maegin-left: 50px;
  }
  nav .false {
    display: none;
  }
  nav ul{
    flex-direction: column;
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    height: 150px;
    margin-top: 10px;
    z-index: 999;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }
  nav ul li{
    font-size: 14px;
    border-bottom: 0.2px solid rgba(0, 0, 0, 0.082);
    display: flex;
    place-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
  }
  }
</style>
